// multicolumn plugin rows
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

// width classes for multicolumn plugin
.col {
  padding-right: $spacing-narrow;

  &:first-child {
    padding-left: 0;
  }

  & > * {
    max-width: 100%;
  }

  &__widthfull {
    width: calc(100% - #{$spacing-narrow});
  }

  &__width1\/2 {
    width: calc(100% - #{$spacing-narrow});

    @include md {
      width: calc(50% - #{$spacing-narrow});
    }
  }

  &__width1\/3 {
    width: calc(100% - #{$spacing-narrow});
    padding-left: 0;
    @include md {
      width: calc(33.33% - #{$spacing-narrow});
    }
  }

  &__width1\/4 {
    width: calc(50% - #{$spacing-narrow});

    @include md {
      width: calc(25% - #{$spacing-narrow});
    }
  }

  &__width3\/4 {
    width: calc(100% - #{$spacing-narrow});
    padding-left: 0;
    @include md {
      width: calc(75% - #{$spacing-narrow});
    }
  }

  &__width2\/3 {
    width: calc(100% - #{$spacing-narrow});
    padding-left: 0;
    @include md {
      width: calc(66.66% - #{$spacing-narrow});
    }
  }
}
