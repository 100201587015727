.footer {
  background-color: $darkblue;
  &__wrapper {
    color: $grey;
    max-width: $screen-xl-min;
    margin: auto;
    padding-bottom: $spacing-narrow;
    a {
      color: $grey;
      text-decoration: none;
    }
  }

  &__nav {
    padding: $spacing-wide;

    &__submenu {
      margin: 0 0 0 1em;
      @include lg {
        margin: 1em 0 0 0;
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include lg {
        flex-wrap: nowrap;
      }
    }

    &__item {
      &:not(:last-child) {
        margin-right: $spacing-wide;
      }
      width: 100%;
      &--spaced {
        margin-top: 1em;
      }
      @include lg {
        margin-top: initial;
        width: initial;
      }
    }
  }
}
