// colors
$white: #fff;
$darkblue: #1f4e7a;
$lightblue: lighten($darkblue, 20%);
$lightgrey: #f4f4f4;
$grey: #a4a4a4;
$text-color: #707070;
$red: #d0021b;

$color-border: #efefef;

// sizes
$base-font-size: 17px;
$submenu-line-height: 3em;

$spacing-wide: 42px;
$spacing-narrow: 16px;

$certificates-height: 85px;

$header-bottomrow-logos-height: 60px;
$header-hero-height: 950px;
$header-row-height: 160px;

// size classes

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1330px;

// color classes

// Breakpoints
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra large devices
@mixin not-md {
  @media (max-width: #{$screen-md-min - 1}) {
    @content;
  }
}

@mixin not-lg {
  @media (max-width: #{$screen-lg-min - 1}) {
    @content;
  }
}

@mixin not-xl {
  @media (max-width: #{$screen-xl-min - 1}) {
    @content;
  }
}

@mixin not-giant {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin shadow {
  box-shadow: 0 0 40px 0 rgba(39, 49, 65, 0.2);
}
