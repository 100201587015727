.flag {
  height: 1.25em;
  width: 1.25em;
  display: inline-block;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  &__de {
    background-image: url(../assets/flag_de.svg);
  }

  &__en-us,
  &__en {
    background-image: url(../assets/flag_uk.svg);
  }
}
