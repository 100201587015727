.breadcrumb {
  ol {
    display: flex;
    padding-bottom: $spacing-narrow;
    flex-wrap: wrap;
  }

  a {
    color: $text-color;
    text-decoration: none;
  }
}

.separator {
  margin-right: 0.2em;
}
