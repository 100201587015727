.maps {
  &__container {
    position: relative;
  }

  &__contact {
    &__container {
      max-width: $screen-xl-min;
      margin: auto;
      position: relative;
      background-color: black;
    }

    & > p a {
      color: $text-color;
      text-decoration: none;
    }
    z-index: 1;
    background-color: $white;

    padding: $spacing-narrow;

    @include md {
      padding: $spacing-wide;
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translate(-0, calc(-50% + 200px));
      left: 0;
      @include shadow;
    }
  }

  &__directions {
    color: $white;
    text-decoration: none;
    background-color: $darkblue;
    text-align: center;
    line-height: 2em;
    display: inline-block;
    padding: 0 1em;
    &__container {
      margin-top: $spacing-wide;
      text-align: center;
    }
  }
}
