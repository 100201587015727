.header {
  width: 100%;

  @include lg {
    &__hero > .slider__fullscreen {
      height: calc(100vh - 2 * #{$header-row-height});
      min-height: $header-row-height * 1.5;
    }

    &__hero > .slider__fixed {
      min-height: $header-row-height * 1.5;
      height: calc(#{$header-hero-height} - 2 * #{$header-row-height});
      max-height: calc(100vh - 2 * #{$header-row-height});
    }
  }

  &__contact-text,
  &__search-text {
    display: none;
    @include xl {
      display: inline;
    }
  }

  &__headline {
    text-align: center;
    background-color: $lightgrey;
    padding: $spacing-wide;
  }

  &__hero-container {
    position: relative;
    overflow: hidden;
  }

  &__link-appointment {
    &__bg {
      background-color: $white;
      width: 100vw;
      height: $base-font-size * 1.85;
      @include xl {
        height: $base-font-size * 2.75;
      }
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      z-index: 99;
      opacity: 0.3;
      @include not-lg {
        display: none;
      }
    }

    padding: 0 1em;
    @include not-lg {
      display: block;
      width: 100%;
      text-align: center;
      line-height: 2em;
    }

    @include lg {
      position: absolute;
      // align box with emergency center thingy
      right: $spacing-narrow + $spacing-wide;
      top: 0;
      z-index: 100;
      font-size: $base-font-size;
      height: $base-font-size * 1.85;
    }

    @include xl {
      font-size: $base-font-size * 1.5;
      height: $base-font-size * 2.75;
    }

    color: $white;
    background-color: $darkblue;
    text-decoration: none;
  }

  &__toprow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: $header-row-height;

    a {
      text-decoration: none;
      color: $text-color;
    }

    position: relative;
    @include md {
      height: $header-row-height;
      padding: 0 $spacing-narrow;
    }
    @include xl {
      height: $header-row-height;
      padding: 0 $spacing-wide;
    }
  }

  &__bottomrow {
    display: flex;
    justify-content: center;
    align-items: center;

    height: $header-row-height * 0.75;
    @include md {
      height: $header-row-height;
    }
  }

  &__deco {
    text-align: center;
    width: 100%;
    & a {
      text-decoration: none;
    }
    & img {
      max-height: $header-bottomrow-logos-height;
      display: inline-block;
      width: auto;
      margin: 0 $spacing-wide / 2;
    }
  }
  &__deco > *,
  &__deco > * > * {
    max-width: 33%;
  }

  &__logo {
    max-width: 20%;
    padding: $spacing-narrow;
    margin-right: auto;
    @include md {
      padding: 0;
    }

    & > * {
      max-width: 100%;
    }
  }

  &__search-and-lang {
    max-width: calc(80% - 150px);
    order: 2;
    display: flex;
    margin-left: auto;
    margin-right: $spacing-narrow;
    &__item {
      margin: 0 $spacing-narrow / 2;
      &--hidden {
        display: none !important;
      }
    }
    @include lg {
      width: 350px;
    }
  }

  font-size: $base-font-size * 0.9;

  @include md {
    font-size: $base-font-size;
  }

  @include xl {
    font-size: $base-font-size * 1.4;
  }
}

#search__input {
  font-family: $base-font;
  font-size: $base-font-size;
  line-height: 1.5em;
  border: solid 1px $text-color;
  border-radius: 0;
  color: $text-color;
  align-self: center;
  // mobile safari
  -webkit-appearance: none;
  width: 100%;
}

.search__results {
  position: absolute;
  background-color: $white;
  z-index: 200;
  width: 670px;
  max-width: calc(100vw - #{2 * $spacing-wide});
  text-overflow: ellipsis;
  padding: 0 $spacing-wide $spacing-wide $spacing-wide;
  top: $header-row-height * 0.8;
  max-height: calc(100vh - #{$header-row-height});
  overflow-y: scroll;
  right: 0;
  cursor: pointer;
  p {
    margin-top: 0;
  }

  li {
    border-top: 1px solid $lightgrey;
  }

  h3 {
    line-height: 1.5em;
  }

  .highlighted {
    color: $darkblue;
  }
}

.search__close {
  // use the asset as background image because IE11
  width: 20px;
  height: 20px;
  position: absolute;
  top: $spacing-narrow;
  right: $spacing-narrow;
  background-image: url('../assets/hamburger_cross.svg');
  z-index: 201;
  cursor: pointer;
}

.search__input--hidden {
  display: none;
}

// mobile safari fix to handle click events
.js-search__label {
  cursor: pointer;
}
