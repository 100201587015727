.aside {
  width: 100%;

  padding-bottom: $spacing-wide;
  &--left {
    margin: 0 $spacing-wide 0 $spacing-narrow;
    display: none;
    @include lg {
      display: block;
      width: calc(25% - #{$spacing-wide + $spacing-narrow});
    }
  }
  &--right {
    margin: 0 $spacing-narrow;
    @include md {
      width: calc(25% - #{$spacing-wide + $spacing-narrow});
      margin: 0 $spacing-narrow 0 $spacing-wide;
    }
  }

  .subnav {
    display: none;

    @include lg {
      display: flex;
      justify-content: space-around;
      border-top: solid $grey 1px;

      & li {
        & a {
          text-decoration: none;
          color: $text-color;
          line-height: 1.1em;
          display: inline-block;
          margin: 0.5em 0 0.5em 0;
        }
      }
    }
  }
}

.subpage {
  &__link-head {
    margin-top: $spacing-narrow;
    font-weight: bold;
    display: block;
    text-decoration: none;
    color: $text-color;
  }

  &__nav__item {
    padding: 0.5em 0 0.5em 0;
    border-bottom: solid $grey 1px;
  }

  &__content {
    & .certificate {
      box-shadow: 2px 2px 12px #707070;
    }

    & > img {
      max-width: 100%;
    }

    & h2 {
      margin-bottom: 0.5em;
    }

    & h4 {
      font-weight: bold;
      margin-top: 1em;
      font-size: 1em;
    }
  }

  &__submenu {
    padding-left: 1em;
  }

  &__nav__item--selected > a {
    font-weight: bold;
  }
}
.main__subnav {
  width: 100%;
}

.subpage-main {
  padding: 0 $spacing-narrow $spacing-wide $spacing-narrow;
  width: calc(100% - #{$spacing-narrow * 2});
  @include lg {
    width: 75%;
    padding: 0 0 $spacing-wide 0;
  }
}

.subpage__content {
  & img {
    padding: $spacing-narrow 0;
  }

  // reset list style to actual lists
  & ol,
  & ul,
  & li {
    list-style: disc;
    margin-left: 0.5em;
  }

  & ol > li {
    list-style: decimal;
  }

  h2,
  h3 {
    margin-top: $spacing-narrow;
  }
}
