.preview-events {
  margin: auto;
  padding: $spacing-wide 0;
  background-color: $lightgrey;
  text-align: center;

  &__link {
    text-decoration: none;
    color: $text-color;
    display: block;
  }
}

.event-wrapper {
  background-color: $lightgrey;
  padding: $spacing-wide 0;
}

.event-list {
  margin: auto;
  max-width: $screen-xl-min;
  &__filter {
    text-align: center;
    margin-bottom: $spacing-narrow;

    &__button {
      background-color: $white;
      color: $text-color;
      padding: $spacing-narrow;
      margin: 0 $spacing-narrow / 2;
      cursor: pointer;
      @include shadow;

      &--selected {
        background-color: $darkblue;
        color: $white;
      }
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }
}

$blocksize: $screen-xl-min / 4 - $spacing-narrow * 3 / 2;
$blocksize-xl: $screen-xl-min / 4 - $spacing-narrow * 3 / 2;

$size-increase-next: 1.2;
.event-block {
  display: flex;
  flex-direction: column;
  width: $blocksize;
  height: $blocksize;
  background-color: $white;
  margin: $spacing-narrow * 3 / 4;

  max-width: calc(100vw - #{$spacing-narrow * 7 / 2});

  text-decoration: none;
  color: $text-color;
  @include shadow;
  @include xl {
    width: $blocksize-xl;
    height: $blocksize-xl;
  }

  &--hidden {
    display: none;
  }

  &__scroll-area {
    overflow-x: scroll;
  }

  &__container {
    padding: $spacing-narrow;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &__date,
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // the height are calculated in a way that the picture lines up
  &__date {
    height: 0.22 * $blocksize;
  }

  &__date-next {
    height: (0.22 * $blocksize) + $blocksize * ($size-increase-next - 1) / 2;
  }

  &__title {
    margin: auto;
    padding: 0 $spacing-narrow;
    overflow: hidden;
    // text-overflow: ellipsis;
    box-sizing: border-box;
    display: inline-block;
    max-width: 100%;
    // white-space: nowrap;
  }

  &__img-wrapper {
    background-color: $lightgrey;
    height: 0.45 * $blocksize;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }

  &__next_caption {
    font-family: $bold-font;
    text-transform: uppercase;
  }

  &__all {
    text-decoration: none;
    color: $text-color;
  }

  &__next {
    width: $blocksize * $size-increase-next;
    height: $blocksize * $size-increase-next;
    @include xl {
      width: $blocksize-xl * $size-increase-next;
      height: $blocksize-xl * $size-increase-next;
    }
  }
}
