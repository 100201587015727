@mixin icon-common {
  content: ' ';
  height: 1em;
  width: 1em;
  display: inline-block;
  transform: translate(0, 0.2em);
  background-size: cover;
}

.icon {
  &__mail::before {
    @include icon-common;
    background-image: url(../assets/mail.svg);
  }

  &__phone::before {
    @include icon-common;
    background-image: url(../assets/phone.svg);
  }

  &__phone2::before {
    @include icon-common;
    background-image: url(../assets/phone2.svg);
    height: 1.25em;
    width: 1.25em;
    transform: translate(0, 0.35em);
  }
  &__fax::before {
    @include icon-common;
    background-image: url(../assets/fax.svg);
  }

  &__link::before {
    @include icon-common;
    background-image: url(../assets/link.svg);
  }

  &__search::before {
    @include icon-common;
    background-image: url(../assets/search.svg);
  }

  &__chevron::before {
    @include icon-common;
    background-image: url(../assets/chevron.svg);
  }

  &__file::before {
    @include icon-common;
    background-image: url(../assets/file.svg);
  }
}
