.event {
  &__container {
    margin: auto;
    max-width: $screen-xl-min;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: $spacing-narrow;
  }

  &__other {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: solid $grey 1px;
    padding: 0.5em 0;
  }

  &__hero {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  &__hero-header {
    &__container {
      max-width: $screen-xl-min;
      margin: auto;
      position: relative;
    }

    color: $white;
    background-color: $darkblue;
    margin: $spacing-wide 0;
    padding: 0.5em 1em;
    display: inline-block;
    z-index: 99;
    position: relative;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }

  &__detail {
    border-top: solid $grey 1px;
    p {
      margin: 0;
    }
  }

  &__flyer {
    text-decoration: none;
    color: $text-color;
  }

  &__subhead {
    font-weight: bold;
    margin-top: 1em;
  }

  &__registration {
    color: $white;
    background-color: $darkblue;
    text-align: center;
    line-height: 2em;
    display: block;
    text-decoration: none;
    margin: $spacing-narrow 0;
  }

  &__information-and-registration {
    margin-top: $spacing-wide;
    border-top: solid $grey 1px;
  }

  &__main {
    width: 100%;
    @include md {
      width: calc(75% - #{2 * $spacing-narrow});
    }
    @include lg {
      width: calc(50% - #{2 * $spacing-narrow});
    }

    padding: 0 $spacing-narrow;
  }
}
