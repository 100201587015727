.slider {
  &__slide__borderbox {
    position: relative;
    height: 100%;
    max-width: $screen-xl-min;
    margin: auto;
  }

  height: 100%;
  &__slidewrapper {
    height: 100%;
  }

  &__slide {
    height: 100%;
    overflow: hidden;
    text-align: center;

    &__img > * {
      @include not-lg {
        width: 100%;
      }
      @include lg {
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        // center image
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    $head-height: $base-font-size * 2.5;

    &__head {
      line-height: $head-height;
      font-size: $base-font-size * 1.5;
      color: $white;
      background-color: $darkblue;
      padding: 0.5em;
      text-transform: uppercase;

      @include lg {
        position: absolute;
        /*font-family: $bold-font;*/
        padding: 0 1em;
        @include xl {
          font-size: $base-font-size * 2;
        }
        a {
          color: $white;
        }

        &--top {
          left: 0;
          @include not-giant {
            left: 14%;
          }
          top: 33%;
        }

        &--bottom {
          left: 0;
          @include not-giant {
            left: 14%;
          }
          bottom: 20%;
        }
      }
    }

    &__text {
      padding: $spacing-narrow 1em;
      background-color: $white;

      @include lg {
        text-align: left;
        position: absolute;
        max-width: calc(32.15% - 2em);

        &--top {
          top: calc(33% + #{$head-height});
          left: 2em;
          @include not-giant {
            left: calc(14% + 2em);
          }
        }

        &--bottom {
          bottom: calc(20% + #{$head-height});
          left: 2em;
          @include not-giant {
            left: calc(14% + 2em);
          }
        }
      }
    }
  }

  &__pagination {
    @include not-lg {
      display: none;
    }
    position: absolute;
    bottom: 0;
  }

  &__next,
  &__prev {
    z-index: 1;
    position: absolute;
    top: 50%;
    margin-top: -2em;
    width: 3em;
    height: 3em;
    background: url('../assets/arrow.png') $darkblue left top / cover no-repeat;
  }

  &__next {
    right: 5%;
  }

  &__prev {
    left: 5%;
    transform: rotate(180deg);
  }
}

// swiper restyling
.swiper-pagination-bullets {
  z-index: 1;
  text-align: center;
}

.swiper-pagination-bullet {
  background-color: $white;
}
