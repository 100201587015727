.person {
  margin-bottom: $spacing-wide;
  &__row {
    display: flex;
    padding: $spacing-narrow;
    margin-top: 1em;
    margin-bottom: 1em;
    @include shadow;
    flex-wrap: wrap;
    @include md {
      flex-wrap: nowrap;
    }
  }
  &__img {
    margin: auto;
    @include md {
      margin: 0 $spacing-wide 0 0;
    }
    align-self: flex-start;
  }
  &__meta {
    width: 100%;
    @include md {
      width: inital;
      display: inline;
      padding-top: $spacing-narrow;
    }
  }
  &__function {
    margin-bottom: 0.5em;
  }
}
