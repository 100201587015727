// mobile
.header__nav {
  display: none;
  order: 1;

  @include xl {
    display: inline-block;
  }

  &--selected > a,
  &--selected > span {
    font-weight: bold;
  }

  &__submenu {
    display: none;
    padding: 0;
  }

  @include not-xl {
    .focus > &__submenu,
    &__item:hover > &__submenu {
      display: block;
    }
  }

  &__item:hover > &__submenu {
    display: block;
  }

  &__link-container {
    background: $lightgrey;
    text-align: center;
    padding: 28px 0 30px;

    @include xl {
      display: none;
    }
  }

  &__link {
    padding: 0 7px;
  }

  .nav-menu {
    max-height: calc(100vh - 244px);
    @include not-xl {
      overflow-y: scroll;
    }
  }

  // show submenus in xl view on hover
  @include xl {
    &__item:hover > &__submenu,
    &__submenu:hover {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: -$spacing-wide + $spacing-narrow;
      width: max-content;
      background-color: $white;
      padding-top: 0.5em;
    }
  }

  @include lg {
    &__subsubmenu {
      display: none;
    }
  }

  &__item {
    position: relative;
    > a {
      line-height: 2em;
      padding: 18px $spacing-narrow / 2 15px;
      text-align: center;
      font-size: 20px;
      letter-spacing: 0;
      border-bottom: 1px solid $color-border;
      display: block;

      @include xl {
        line-height: inherit;
        padding: 0 $spacing-narrow / 2;
        text-align: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        border-bottom: inherit;
        display: inherit;
      }
    }

    &--is-parent:after {
      content: url('../assets/chevron.svg');
      display: block;
      position: absolute;
      right: 34px;
      top: 24px;
      @include xl {
        display: none;
      }
    }

    @include xl {
      text-align: left;
      display: inline;
      padding: 0 $spacing-narrow / 2;
    }
  }
  &__subsubitem {
    text-align: center;
  }
  &__submenu &__item {
    background-color: $white;
    z-index: 200;
    line-height: 2em;

    > a {
      border-bottom: 0;
      padding-bottom: 0;

      @include xl {
        padding: 0;
      }
    }

    &:last-child > a {
      padding-bottom: 15px;
      border-bottom: 1px solid $color-border;

      @include xl {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    @include xl {
      padding-left: $spacing-wide;
      padding-right: $spacing-wide;
    }
  }

  &__lang__chooser {
    display: inline;
    position: relative;
  }

  &__lang__item {
    margin: 0 $spacing-narrow / 2;
    border: solid black 1px;
    &--selected {
      border: solid $lightblue 3px;
      transform: translate(0, 2px);
    }
  }

  &__submenu &__item:last-child {
    @include xl {
      padding-bottom: $spacing-wide;
    }
  }
}

.header__nav--toggled {
  border-top: solid 1px $lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: $white;
  width: 100%;
  z-index: 200;
  height: calc(100vh - 160px);

  @include xl {
    border-top: 0;
    display: inline-block;
    position: relative;
    width: initial;
    height: auto;
    top: initial;
  }
}

.header__nav-toggle {
  order: 4;
  padding: 32px 28px 31px;
  border-left: 1px solid $color-border;
  &:after {
    content: url('../assets/hamburger.svg');
  }

  &[aria-expanded='true'] {
    border-left: 0;

    &:after {
      content: url('../assets/hamburger_cross.svg');
    }
  }

  @include md {
    padding: inherit;
    border-left: inherit;
  }

  @include xl {
    display: none;
  }
}
