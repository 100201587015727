@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=74abe1c8-de43-4606-b616-399838ba153e');
@font-face {
  font-family: 'Opinion W05';
  font-weight: normal;
  src: url('../fonts/234a4bd4-d6f6-4990-82b6-657c0646f2c7.eot?#iefix');
  src: url('../fonts/234a4bd4-d6f6-4990-82b6-657c0646f2c7.eot?#iefix')
      format('eot'),
    url('../fonts/6733bbb8-ecc3-4b7d-aa0a-a7d41e6f0481.woff2') format('woff2'),
    url('../fonts/4aed75af-e2d5-4764-b47a-8610a2751296.woff') format('woff'),
    url('../fonts/a1d40d27-cc7b-42f1-a798-ced0aa9abc35.ttf') format('truetype');
}
@font-face {
  font-family: 'Opinion W05';
  font-weight: bold;
  src: url('../fonts/df86446d-e9ed-4052-b6ab-447f9102b153.eot?#iefix');
  src: url('../fonts/df86446d-e9ed-4052-b6ab-447f9102b153.eot?#iefix')
      format('eot'),
    url('../fonts/4d0b5d39-3da2-4957-9552-7e66dbf5288f.woff2') format('woff2'),
    url('../fonts/9b2d3f79-a333-4688-8410-5b166022a017.woff') format('woff'),
    url('../fonts/5a2b109a-5da7-43bd-b971-11eab7baf9b9.ttf') format('truetype');
}

$base-font: 'Opinion W05', sans-serif;
$bold-font: 'Opinion W05 Bold', sans-serif;

a {
  color: $darkblue;
}

body {
  font-family: $base-font;
  font-size: $base-font-size;
  line-height: 1.5;
  color: $text-color;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $darkblue;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
