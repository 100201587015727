// the elements in the main body consist of django cms placeholders and we have
// no easy way to iterate through them to give them classes, so we have to live
// with this unfortunate selector
.main > * {
  max-width: $screen-xl-min * 0.9;
  margin: $spacing-wide auto;
  position: relative;
}

.main {
  padding: $spacing-narrow;
}
.certificates {
  padding: $spacing-wide $spacing-narrow;
}

.main,
.certificates,
.content {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 1) 100%
    ),
    linear-gradient(rgba(248, 248, 248, 1), rgba(255, 255, 255, 1) 100px);
}

.certificates {
  text-align: center;
}
// all certificates should have the same height
.certificates > img {
  max-height: $certificates-height;
  margin: $spacing-narrow $spacing-narrow / 2;
  max-width: 90%;
}

.content {
  padding-top: $spacing-wide;
  display: flex;
  justify-content: space-around;
  max-width: $screen-xl-min;
  margin: auto;
}
