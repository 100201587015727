.emergency-call {
  order: -1;
  display: flex;
  width: 100%;

  padding: $spacing-narrow;
  align-items: center;
  border-bottom: solid 1px $lightgrey;

  @include md {
    // dirty IE-Fix
    width: 270px !important;
    order: 2;
    width: auto;
    border-bottom: 0;
  }

  &__cross {
    order: 0;
    display: flex;
    align-items: center;
    @include md {
      order: 2;
    }
  }

  &__cross::before {
    content: '';
    background-image: url(../assets/cross.svg);
    background-size: cover;
    // IE-Fix
    display: block;
    width: 1.25em;
    height: 1.25em;
    @include md {
      width: 2.5em;
      height: 2.5em;
    }
  }

  &__label {
    order: 1;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 0.5em;
    margin-right: 0.5em;
    @include md {
      text-align: right;
      flex-direction: column;
    }
  }

  &__number {
    margin-left: 0.5em;
  }
}
